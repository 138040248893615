.artwork-div {
    height: 30em;
    margin-top: 2em;
    margin-bottom: 2em;
}

.artwork-image {
    /* Center. */
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 30em;
    max-width: 100%;
    -o-object-fit: contain;
    object-fit: contain;
}

/* General Kenobi image has special hover effect. */
#kenobi-image img {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: #f8f8f8;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
}

#kenobi-image img.top:hover {
    opacity: 0;
}

#fancy-pants-image {
    height: 22em;
}
