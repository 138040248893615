.outer-container {
    display: grid;
    grid-template-columns: 20% auto;
    grid-template-areas: "nav content";
}

.contents-nav {
    position: -webkit-sticky; /* Safari earlier versions. */
    position: sticky;
    top: 3.2em;
    align-self: start;
}

.contents-ol {
    list-style-type: none;
}

.contents-li {
    margin-top: 1em;
}

.contents-item {
    text-decoration: none;
    color: #636363;
}

.contents-item:hover {
    color: #008388;
}

.photo {
    width: 4em;
    height: 4em;
    border-radius: 2em;
    transform: translate(0, 0.9em);
    margin-right: 1.5em;
}

.name-title {
    padding-top: 0.2em;
    font-size: 3em;
    font-family: "Oleo Script", "Georgia", serif;
    background-image: linear-gradient(to right, #249141, #249c8a, #079cb3, #3464eb);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    color: transparent;
}

.section {
    /* This allows linking via table of contents to arrive at the right place. */
    padding-top: 1.5em;
}
