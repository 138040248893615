.embedded-video-container {
    display: flex;
    margin: 0.2em 0 2em;
    overflow: scroll;
}

.videos-title {
    font-size: 3em;
    font-family: "Oleo Script", "Georgia", serif;
    background-image: linear-gradient(to right, #249141, #249c8a, #079cb3, #3464eb);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    color: transparent;
}

.embedded-video {
    margin: 0.1em;
}
