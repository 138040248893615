@import url(https://fonts.googleapis.com/css2?family=Oleo+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap);
/* Google Fonts. */

html {
    scroll-behavior: smooth; /* Not supported on all browsers. */
}

body {
    margin: 0;
    background-color: #f8f8f8;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.text-div {
    padding-left: 4%;
    padding-right: 4%;
    font-family: "Open Sans", sans-serif;
    font-size: 1em;
}

/* These are the amounts that feel right to me, to keep some space
   between the content and header/footer. */
.top-margin {
    margin-top: 1em;
}

.bottom-margin {
    margin-bottom: 1.8em;
}

.non-footer-content {
    min-height: 80%;
}

.artwork-div {
    height: 30em;
    margin-top: 2em;
    margin-bottom: 2em;
}

.artwork-image {
    /* Center. */
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 30em;
    max-width: 100%;
    object-fit: contain;
}

/* General Kenobi image has special hover effect. */
#kenobi-image img {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    background-color: #f8f8f8;
    transition: opacity 1s ease-in-out;
}

#kenobi-image img.top:hover {
    opacity: 0;
}

#fancy-pants-image {
    height: 22em;
}

.social-container {
    background: #eee;
    padding: 25px 50px;
}

a.social {
    margin: 0 1rem;
    transition: -webkit-transform 250ms;
    transition: transform 250ms;
    transition: transform 250ms, -webkit-transform 250ms;
    display: inline-block;
}

a.social:hover {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
}

a.youtube {
    color: #eb3223;
}

a.github {
    color: #24292e;
}

a.linkedin {
    color: #2867b2;
}

.expansion {
    width: 95%;
}

.embedded-video-container {
    display: flex;
    margin: 0.2em 0 2em;
    overflow: scroll;
}

.videos-title {
    font-size: 3em;
    font-family: "Oleo Script", "Georgia", serif;
    background-image: linear-gradient(to right, #249141, #249c8a, #079cb3, #3464eb);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    color: transparent;
}

.embedded-video {
    margin: 0.1em;
}

.project-box {
    height: 9em;
    margin-left: 4em;
    margin-right: 4em;
    background-color: #f1f1f1;
    border: 0.18em solid grey;
    border-radius: 0.8em;
    display: grid;
    grid-template-columns: 9em auto;
    overflow: hidden;
    transition: box-shadow 0.3s;
}

.project-box:hover {
    box-shadow: 0 0 0.8em #849e9d;
}

.thumbnail {
    width: 9em;
    height: 9em;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}

.project-box:hover .thumbnail {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

.project-text {
    padding: 1em 1em 1em 2em;
    border-left: 0.18em solid grey;
    color: black;
    /* Needed to hide the expanding thumbnail. */
    z-index: 5000;
    background-color: #f8f8f8;
}

.project-title {
    font-size: 1.1em;
    font-weight: bold;
    margin-bottom: 0.3em;
    transition: color 0.3s;
}

.project-box:hover .project-title {
    color: #008388;
}

.link {
    text-decoration: none;
}

.upload-image-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.5em;
    margin-bottom: 2em;
}

.upload-image-section > .upload-button {
    margin-left: 0.5em;
    cursor: pointer;
}

.convert-button-container {
    margin-bottom: 0.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.convert-button-container > .background-color-text-field {
    margin-left: 0.5em;
}

.outer-container {
    display: grid;
    grid-template-columns: 25% auto;
}

.dimension-text-field {
    width: 15em;
}

.controls {
    padding: 2em 0 2.5em 0;
    background-color: #e1e3eb;
    font-size: 0.9em;
    position: -webkit-sticky; /* Safari earlier versions. */
    position: sticky;
    top: 3.2em;
    align-self: start;
}

.ref-shift-controls {
    width: 70%;
    z-index: 9500;
    margin: 0 auto;
}

.ref-shift-text-div {
    display: grid;
    grid-template-columns: 8em auto;
    margin-bottom: 1em;
}

.ref-shift-text {
    width: 7em;
    height: 2.6em;
}

.ref-shift-text-error {
    height: 2.6em;
    color: #df3434;
}

.event-selector-div {
    width: 80%;
    margin: 0 auto;
}

.event-selector-outer {
    margin-top: 0.5em;
    height: 4.5em;
    overflow-x: auto;
    overflow-y: hidden;
}

.event-selector {
    width: 88em; /* Hard-coded for 22 events, 4em each. */
    display: block;
}

.event-image {
    width: 4em;
    height: 4em;
    object-fit: contain;
    cursor: pointer;
}

#custom-image-icon {
    color: #aaa;
    cursor: pointer;
    width: 1em;
    height: 1em;
    margin: 0.1em 0.15em 0.25em;
}

.custom-event-tooltip {
    margin-bottom: 2.6em;
}

.add-object-text-error {
    color: #df3434;
}

.control-buttons {
    width: 70%;
    margin: 0 auto;
    margin-top: 0.5em;
    margin-bottom: 1em;
}

.scenario-selector {
    width: 80%;
    margin: 0 auto;
}

.spacetime-toggle {
    width: 30em;
    margin: 1em auto 1.5em;
}

/* Buttons can't have margins, so we must use a blank div instead. */
.spacing {
    padding-top: 0.5em;
}

.contextmenu {
    position: absolute;
    width: 4em;
    background-color: white;
    box-shadow: 0 0 0.3em grey;
    border-radius: 0.2em;
    z-index: 9999;
}

.contextmenu .menu-option {
    font-size: 0.9em;
    width: 100%;
    background-color: white;
    border: none;
    margin: 0;
    padding: 0.5em;
    cursor: pointer;
}

.contextmenu .menu-option:hover {
    background-color: #e1e3eb;
}

.dialog {
    max-width: 100%;
}

.dialog-content {
    padding: 0 1em 0.5em;
    white-space: nowrap;
    overflow: contain;
    -ms-scroll-chaining: none;
        overscroll-behavior: contain;
}

.dialog-line {
    margin-bottom: 0.5em;
    display: flex;
    align-items: center;
}

.center-content {
    justify-content: center;
}

.dialog-note {
    font-size: 0.8em;
}

.space {
    padding-right: 0.5em;
}

.outer-container {
    display: grid;
    grid-template-columns: 20% auto;
    grid-template-areas: "nav content";
}

.contents-nav {
    position: -webkit-sticky; /* Safari earlier versions. */
    position: sticky;
    top: 3.2em;
    align-self: start;
}

.contents-ol {
    list-style-type: none;
}

.contents-li {
    margin-top: 1em;
}

.contents-item {
    text-decoration: none;
    color: #636363;
}

.contents-item:hover {
    color: #008388;
}

.photo {
    width: 4em;
    height: 4em;
    border-radius: 2em;
    -webkit-transform: translate(0, 0.9em);
            transform: translate(0, 0.9em);
    margin-right: 1.5em;
}

.name-title {
    padding-top: 0.2em;
    font-size: 3em;
    font-family: "Oleo Script", "Georgia", serif;
    background-image: linear-gradient(to right, #249141, #249c8a, #079cb3, #3464eb);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    color: transparent;
}

.section {
    /* This allows linking via table of contents to arrive at the right place. */
    padding-top: 1.5em;
}

.left-half {
    width: 50%;
    text-align: left;
    align-self: center;
}

.right-half {
    width: 50%;
    text-align: right;
    align-self: center;
}

#footer-div {
    font-size: 1em;
    height: 3em;
    padding: 1em 2em 1em 1.8em;
    background-color: #d4dad8;
    box-shadow: 0px 500px 0px 500px #d4dad8;
    display: flex;
}

.logo {
    width: 2.5em;
    height: 2.5em;
    border-radius: 1.25em;
    margin-right: 1em;
    align-self: center;
}

.nav-bar {
    position: -webkit-sticky; /* Safari earlier versions. */
    position: sticky;
    padding-top: 0.2em;
    top: 0;
    background-color: #f8f8f8;
    z-index: 9000;
}

/* When user hovers over a navigation option, the text changes color,
   an underline appears, and the background darkens.
   When user is at a route, that navigation option text has the changed color. */

.nav-option {
    position: relative;
    display: inline-block;
    padding: 0.5em 1em 0.1em 1em;
    margin-bottom: 0.4em;
    line-height: 24px;
    height: 24px;
    color: black;
    text-decoration: none;
    font-size: 1.3em;
}

.nav-option::after {
    content: "";
    position: absolute;
    display: block;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    border-bottom: 2px solid #008388;
}

.nav-option,
.nav-option::after,
.nav-option-div,
.nav-option-div::after {
    transition: all 0.3s linear;
}

.nav-option:hover,
.active-nav-option {
    color: #008388;
}

.nav-option:hover::after {
    left: 0;
    width: 100%;
}

.nav-option-div {
    display: inline-block;
    border-radius: 0.5em;
}

.nav-option-div:hover {
    background-color: #e1e3eb;
}

#science-banner-image {
    width: 100%;
}

