.left-half {
    width: 50%;
    text-align: left;
    align-self: center;
}

.right-half {
    width: 50%;
    text-align: right;
    align-self: center;
}

#footer-div {
    font-size: 1em;
    height: 3em;
    padding: 1em 2em 1em 1.8em;
    background-color: #d4dad8;
    box-shadow: 0px 500px 0px 500px #d4dad8;
    display: flex;
}

.logo {
    width: 2.5em;
    height: 2.5em;
    border-radius: 1.25em;
    margin-right: 1em;
    align-self: center;
}
