.nav-bar {
    position: -webkit-sticky; /* Safari earlier versions. */
    position: sticky;
    padding-top: 0.2em;
    top: 0;
    background-color: #f8f8f8;
    z-index: 9000;
}

/* When user hovers over a navigation option, the text changes color,
   an underline appears, and the background darkens.
   When user is at a route, that navigation option text has the changed color. */

.nav-option {
    position: relative;
    display: inline-block;
    padding: 0.5em 1em 0.1em 1em;
    margin-bottom: 0.4em;
    line-height: 24px;
    height: 24px;
    color: black;
    text-decoration: none;
    font-size: 1.3em;
}

.nav-option::after {
    content: "";
    position: absolute;
    display: block;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    border-bottom: 2px solid #008388;
}

.nav-option,
.nav-option::after,
.nav-option-div,
.nav-option-div::after {
    transition: all 0.3s linear;
}

.nav-option:hover,
.active-nav-option {
    color: #008388;
}

.nav-option:hover::after {
    left: 0;
    width: 100%;
}

.nav-option-div {
    display: inline-block;
    border-radius: 0.5em;
}

.nav-option-div:hover {
    background-color: #e1e3eb;
}

#science-banner-image {
    width: 100%;
}
