.outer-container {
    display: grid;
    grid-template-columns: 25% auto;
}

.dimension-text-field {
    width: 15em;
}

.controls {
    padding: 2em 0 2.5em 0;
    background-color: #e1e3eb;
    font-size: 0.9em;
    position: -webkit-sticky; /* Safari earlier versions. */
    position: sticky;
    top: 3.2em;
    align-self: start;
}

.ref-shift-controls {
    width: 70%;
    z-index: 9500;
    margin: 0 auto;
}

.ref-shift-text-div {
    display: grid;
    grid-template-columns: 8em auto;
    margin-bottom: 1em;
}

.ref-shift-text {
    width: 7em;
    height: 2.6em;
}

.ref-shift-text-error {
    height: 2.6em;
    color: #df3434;
}

.event-selector-div {
    width: 80%;
    margin: 0 auto;
}

.event-selector-outer {
    margin-top: 0.5em;
    height: 4.5em;
    overflow-x: auto;
    overflow-y: hidden;
}

.event-selector {
    width: 88em; /* Hard-coded for 22 events, 4em each. */
    display: block;
}

.event-image {
    width: 4em;
    height: 4em;
    object-fit: contain;
    cursor: pointer;
}

#custom-image-icon {
    color: #aaa;
    cursor: pointer;
    width: 1em;
    height: 1em;
    margin: 0.1em 0.15em 0.25em;
}

.custom-event-tooltip {
    margin-bottom: 2.6em;
}

.add-object-text-error {
    color: #df3434;
}

.control-buttons {
    width: 70%;
    margin: 0 auto;
    margin-top: 0.5em;
    margin-bottom: 1em;
}

.scenario-selector {
    width: 80%;
    margin: 0 auto;
}

.spacetime-toggle {
    width: 30em;
    margin: 1em auto 1.5em;
}

/* Buttons can't have margins, so we must use a blank div instead. */
.spacing {
    padding-top: 0.5em;
}

.contextmenu {
    position: absolute;
    width: 4em;
    background-color: white;
    box-shadow: 0 0 0.3em grey;
    border-radius: 0.2em;
    z-index: 9999;
}

.contextmenu .menu-option {
    font-size: 0.9em;
    width: 100%;
    background-color: white;
    border: none;
    margin: 0;
    padding: 0.5em;
    cursor: pointer;
}

.contextmenu .menu-option:hover {
    background-color: #e1e3eb;
}

.dialog {
    max-width: 100%;
}

.dialog-content {
    padding: 0 1em 0.5em;
    white-space: nowrap;
    overflow: contain;
    overscroll-behavior: contain;
}

.dialog-line {
    margin-bottom: 0.5em;
    display: flex;
    align-items: center;
}

.center-content {
    justify-content: center;
}

.dialog-note {
    font-size: 0.8em;
}

.space {
    padding-right: 0.5em;
}
