/* Google Fonts. */
@import url("https://fonts.googleapis.com/css2?family=Oleo+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");

html {
    scroll-behavior: smooth; /* Not supported on all browsers. */
}

body {
    margin: 0;
    background-color: #f8f8f8;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.text-div {
    padding-left: 4%;
    padding-right: 4%;
    font-family: "Open Sans", sans-serif;
    font-size: 1em;
}

/* These are the amounts that feel right to me, to keep some space
   between the content and header/footer. */
.top-margin {
    margin-top: 1em;
}

.bottom-margin {
    margin-bottom: 1.8em;
}

.non-footer-content {
    min-height: 80%;
}
